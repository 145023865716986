// app/javascript/controllers/image_loader_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "placeholder"]

  connect() {
    // Chequeamos si la imagen está en caché del navegador
    if (this.imageTarget.complete && this.imageTarget.naturalWidth !== 0) {
      // Si está en caché, mostramos la imagen inmediatamente
      this.showImage()
    } else {
      // Si no está en caché, mostramos el placeholder
      this.showPlaceholder()
    }
  }

  showImage() {
    this.imageTarget.classList.remove("opacity-0")
    this.imageTarget.classList.add("opacity-100")
    this.placeholderTarget.classList.add("hidden")
  }

  showPlaceholder() {
    this.placeholderTarget.classList.remove("hidden")
    this.imageTarget.classList.add("opacity-0")
  }

  onLoad() {
    // Cuando la imagen termina de cargar
    this.showImage()
  }

  onError() {
    this.placeholderTarget.innerHTML = `
      <div class="absolute inset-0 flex items-center justify-center bg-slate-800 rounded-lg">
        <p class="text-slate-400 text-sm">Error al cargar la imagen</p>
      </div>
    `
  }
}