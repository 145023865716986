import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "categoryButton"]

  async connect() {
    try {
      await this.initializeSwiper()
    } catch (error) {
      console.error("Error initializing Swiper:", error)
    }
  }

  async initializeSwiper() {

    while (!window.Swiper) {
      await new Promise(resolve => setTimeout(resolve, 100))
    }

    this.swiper = new window.Swiper(this.containerTarget, {
      slidesPerView: 'auto',
      spaceBetween: 15,
      loop: true,
      loopAdditionalSlides: 5,
      speed: 600,
      centeredSlides: true, // Centra el slide activo
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.next_category',
        prevEl: '.prev_category'
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true
      },
      freeMode: {
        enabled: true,
        sticky: true,
        momentumBounce: true,
        momentumRatio: 0.8,
      },
      breakpoints: {
        640: {
          slidesPerView: 'auto',
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 'auto',
          spaceBetween: 15,
        }
      },
      on: {
        init: function() {
          // Asegura que el loop sea suave desde el inicio
          this.el.style.cursor = 'grab';
       
        },
        touchStart: function() {
          this.el.style.cursor = 'grabbing';
        },
        touchEnd: function() {
          this.el.style.cursor = 'grab';
        },
        slideChange: () => {
            if (this.swiper) {
              this.handleSlideChange(); // Llama a handleSlideChange cuando el slide cambie
            }
          }
      }
    })

    // Espera a que Swiper esté completamente cargado antes de llamar a handleSlideChange
    await this.waitForSwiperInitialization();
    this.handleSlideChange();
  }


  async waitForSwiperInitialization() {
    while (!this.swiper || !this.swiper.initialized) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }


  handleSlideChange() {

 if (!this.swiper) return; // Asegúrate de que this.swiper esté definido
    // Remover clases activas de todos los botones
    this.categoryButtonTargets.forEach(button => {
      button.classList.remove(
        'bg-slate-600/40',
        'border-sky-600',
        'border-2',
        'text-[cyan]',
        'transform',
        'scale-105',
        'shadow-lg',
        'shadow-cyan-500/50'
      )
    })
    
    // Añadir clases activas al botón correspondiente al slide actual
    console.log("Enrteo aqui"+ this.swiper)
    const currentIndex = this.swiper.realIndex
    if (this.categoryButtonTargets[currentIndex]) {
      this.categoryButtonTargets[currentIndex].classList.add(
        'bg-slate-600/40',
        'border-sky-600',
        'border-2',
        'text-[cyan]',
        'transform',
        'scale-105',
        'shadow-lg',
        'shadow-cyan-500/50'
      )
    }
  }

  prev() {
    if (this.swiper) {
      this.swiper.slidePrev()
    }
  }

  next() {
    if (this.swiper) {
      this.swiper.slideNext()
    }
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}