import { Controller } from "@hotwired/stimulus"
import { loadModule } from 'cld3-asm';
export default class extends Controller {
  static targets = [
    "prompt", 
    "model", 
    "result", 
    "notify", 
    "progress", 
    "progressBar", 
    "generateButton", 
    "loader", 
    "imageContainer", 
    "copyNotification", 
    "style", 
    "mainImage", 
    "thumbnailContainer",
    "aspectRatioSelect",
    "selectedRatio",
    "customInputs",
    "customWidth",
    "customHeight",
    "modal",
    "alert",
    "watermarked", 
    "original", 
    "buttonText",
    "numVariations",
    "variationDisplay",
    "seed",
    "guidanceScale",
    "numInferenceSteps",
    "isPublic",
    "seedError",
    "variationsError",
    "progressContainer",
    "progressCircle", "progressText"
  ]


  static values = {
    minGuidance: { type: Number, default: 1 },
    maxGuidance: { type: Number, default: 20 },
    minSteps: { type: Number, default: 1 },
    maxSteps: { type: Number, default: 100 },  // Actualizado a 100
    defaultGuidance: { type: Number, default: 7.5 },  // Valor por defecto de Hugging Face
    defaultSteps: { type: Number, default: 50 }  // Valor por defecto de Hugging Face
  }

  initialize() {
    this.progressInterval = null
    this.currentProgress = 0
    this.isGenerating = false
  }

    
  connect() {
    console.log("Ingreso a IMAGE GENERATOR");
    this.currentImageId = null;
    const defaultRatio = this.element.dataset.aspectRatio || "1:1";
    this.updateVariations()
    this.updateSelectedRatio(defaultRatio);
    this.initializeValidations()
    if (this.hasProgressContainerTarget) {
      this.progressContainerTarget.classList.add('opacity-0')
      this.progressContainerTarget.classList.remove('invisible')
    }
    
    // Si existe un select, establecer su valor inicial
    if (this.hasAspectRatioSelectTarget) {
      this.aspectRatioSelectTarget.value = defaultRatio;
    }

     // Inicializar el detector de idioma
     this.initializeLanguageDetector();
  }

  async initializeLanguageDetector() {
    try {
      console.log('Intentando cargar CLD3');
      
      // Load the CLD3 module
      this.languageDetector = await loadModule();
      console.log('CLD3 cargado');

      // List available methods
      console.log('Métodos disponibles:', Object.keys(this.languageDetector));
    } catch (error) {
      console.error('Error detallado:', error);
      console.error('Tipo de error:', error.name);
      console.error('Mensaje de error:', error.message);
    }
  }

  initializeValidations() {
    if (this.guidanceScaleTarget) {
      this.guidanceScaleTarget.value = this.guidanceScaleTarget.value || "7"
    }
    if (this.numInferenceStepsTarget) {
      this.numInferenceStepsTarget.value = this.numInferenceStepsTarget.value || "20"
    }
  }

  validateSeed(event) {
    const value = this.seedTarget.value
    if (value && !/^\d*$/.test(value)) {
      this.showError(this.seedErrorTarget, "El seed debe contener solo números")
      this.seedTarget.value = value.replace(/\D/g, '')
    } else {
      this.hideError(this.seedErrorTarget)
    }
  }

  showErrorMessage(message) {
    if (this.hasAlertTarget) {
      this.alertTarget.textContent = message
      this.alertTarget.classList.remove('hidden')
      setTimeout(() => {
        this.alertTarget.classList.add('hidden')
      }, 5000)
    }
  }

  updateVariations() {
    const value = this.numVariationsTarget.value
    this.variationDisplayTarget.textContent = value
    this.validateVariations()
  }

  validateVariations() {
    const value = parseInt(this.numVariationsTarget.value)
    if (isNaN(value) || value < 1 || value > 4) {
      this.showError(this.variationsErrorTarget, "El número de variaciones debe estar entre 1 y 4")
    } else {
      this.hideError(this.variationsErrorTarget)
    }
  }

  initializeValidations() {
    if (this.guidanceScaleTarget) {
      this.guidanceScaleTarget.value = this.defaultGuidanceValue.toString()
      this.updateGuidanceDisplay(this.defaultGuidanceValue)
    }
    if (this.numInferenceStepsTarget) {
      this.numInferenceStepsTarget.value = this.defaultStepsValue.toString()
      this.updateStepsDisplay(this.defaultStepsValue)
    }
  }

  validateGuidanceScale(event) {
    const value = parseFloat(this.guidanceScaleTarget.value)
    if (isNaN(value) || value < this.minGuidanceValue || value > this.maxGuidanceValue) {
      this.showAlert(`El Guidance Scale debe estar entre ${this.minGuidanceValue} y ${this.maxGuidanceValue}`)
      const correctedValue = Math.min(Math.max(value || this.defaultGuidanceValue, this.minGuidanceValue), this.maxGuidanceValue)
      this.guidanceScaleTarget.value = correctedValue
    }
    this.updateGuidanceDisplay(this.guidanceScaleTarget.value)
  }

  validateInferenceSteps(event) {
    const value = parseInt(this.numInferenceStepsTarget.value)
    if (isNaN(value) || value < this.minStepsValue || value > this.maxStepsValue) {
      this.showAlert(`Los Inference Steps deben estar entre ${this.minStepsValue} y ${this.maxStepsValue}`)
      const correctedValue = Math.min(Math.max(value || this.defaultStepsValue, this.minStepsValue), this.maxStepsValue)
      this.numInferenceStepsTarget.value = correctedValue
    }
    this.updateStepsDisplay(this.numInferenceStepsTarget.value)
  }

  updateGuidanceDisplay(value) {
    const displayElement = document.getElementById('guidanceValue')
    if (displayElement) {
      displayElement.textContent = value
    }
  }

  updateStepsDisplay(value) {
    const displayElement = document.getElementById('stepsValue')
    if (displayElement) {
      displayElement.textContent = value
    }
  }


  showError(target, message) {
    target.textContent = message
    target.classList.remove('hidden')
  }

  hideError(target) {
    target.textContent = ""
    target.classList.add('hidden')
  }

  // Verificar todas las validaciones antes de generar la imagen
  validateAll() {
    this.validateSeed()
    this.validateVariations()
    this.validateGuidanceScale()
    this.validateInferenceSteps()

    // Si hay algún error, detener la generación
    if (!this.seedErrorTarget.classList.contains('hidden') ||
        !this.variationsErrorTarget.classList.contains('hidden')
      ) {
      return false
    }
    return true
  }

  toggle() {
    this.watermarkedTarget.classList.toggle("hidden")
    this.originalTarget.classList.toggle("hidden")
    
    if (this.watermarkedTarget.classList.contains("hidden")) {
      this.buttonTextTarget.textContent = "Ver con marca"
    } else {
      this.buttonTextTarget.textContent = "Ver sin marca"
    }
  }

  openModal(event) {
    console.log("click en open modal")
    const imageId = event.currentTarget.dataset.imageId
    fetch(`/ai_images/${imageId}/modal`)
      .then(response => response.text())
      .then(html => {
        this.modalTarget.innerHTML = html
        this.modalTarget.classList.remove('hidden')
      })
  }

  closeModal() {
    this.modalTarget.classList.add('hidden')
    this.modalTarget.innerHTML = ''
  }

  updateMainImage(event) {
    const newImageUrl = event.currentTarget.dataset.imageUrl;
    const imageId = event.currentTarget.dataset.imageId;

    // Actualiza la imagen principal
    if (this.mainImageTarget) {
      this.mainImageTarget.src = newImageUrl;
      this.mainImageTarget.dataset.imageId = imageId;

      // Muestra un mensaje de éxito (opcional)
      this.showAlert(`¡Imagen ${imageId} actualizada!`);
    } else {
      console.error("mainImageTarget no está definido");
    }
  }
  showAlert(message) {
    const alert = this.alertTarget;
    alert.textContent = message;
    alert.classList.remove("hidden");

    // Ocultar el mensaje después de 2 segundos
    setTimeout(() => {
      alert.classList.add("hidden");
    }, 2000);
  }

  selectAspectRatio(event) {
    this.updateSelectedRatio(event.target.value)
  }

  toggleCustomRatio() {
    this.customInputsTarget.classList.toggle('hidden')
  }


  applyCustomRatio() {
    const width = this.customWidthTarget.value;
    const height = this.customHeightTarget.value;
    
    if (width && height) {
      const ratio = `${width}:${height}`;
      this.updateSelectedRatio(ratio);
      
      // Clear the aspect ratio select if custom dimensions are used
      if (this.hasAspectRatioSelectTarget) {
        this.aspectRatioSelectTarget.value = ''; // Clear the select
      }
      
    }
  }

  updateSelectedRatio(ratio) {
    if (this.hasSelectedRatioTarget) {
      this.selectedRatioTarget.textContent = ratio
    }
    // Actualizar el dataset del elemento para mantener la consistencia
    this.element.dataset.aspectRatio = ratio;
  }

  // Método para obtener la relación de aspecto actual
  getCurrentAspectRatio() {
    console.log("Entro asopect ratio" + this.element.dataset.aspectRatio)
    return this.element.dataset.aspectRatio || "1:1";
  }

  clearContainer() {
    // Limpiar imagen principal
    const mainImageContent = this.mainImageTarget.querySelector('img')
    if (mainImageContent) {
      mainImageContent.remove()
    }

    // Limpiar miniaturas
    this.thumbnailContainerTargets.forEach(container => {
      const thumbnail = container.querySelector('img')
      if (thumbnail) {
        thumbnail.remove()
      }
      container.classList.add('bg-gray-200')
    })
  }
  
  startLoading() {
    if (!this.hasProgressContainerTarget) return
    
    // Mostrar contenedor usando opacity
    this.progressContainerTarget.classList.remove('opacity-0')
    this.progressContainerTarget.classList.add('opacity-100')
    
    // Resetear el progreso
    if (this.hasProgressCircleTarget) {
      this.progressCircleTarget.style.transform = 'rotate(0deg)'
    }
    if (this.hasProgressTextTarget) {
      this.progressTextTarget.textContent = '0%'
    }
    
    this.startProgress()
  }

  async generate(event) {
    event.preventDefault()
    
    if (!this.validateAll()) return
    
    // Evitar múltiples generaciones simultáneas
    if (this.isGenerating) return
    
    try {
      this.isGenerating = true
      // Resetear completamente el estado
      this.resetState()
      const params = await this.getImageParams(); // Asegúrate de usar await aquí
      await this.sendGenerationRequest(params)
    } catch (error) {
      console.error('Error en generate:', error)
      this.handleError(error)
    } finally {
      this.isGenerating = false
    }
  }

  resetState() {
    this.clearContainer()
    // Asegurar que el progreso esté completamente reseteado
    if (this.hasProgressContainerTarget) {
      this.progressContainerTarget.classList.remove('opacity-0')
      this.progressContainerTarget.classList.add('opacity-100')
      
      if (this.hasProgressCircleTarget) {
        this.progressCircleTarget.style.transform = 'rotate(0deg)'
        this.progressCircleTarget.style.clipPath = 'polygon(50% 50%, -50% 50%, -50% -50%, 50% -50%, 50% 50%, 0% 50%)'
      }
      
      if (this.hasProgressTextTarget) {
        this.progressTextTarget.textContent = '0%'
      }
    }
  }

  async getImageParams() {
    const width = this.customWidthTarget.value;
    const height = this.customHeightTarget.value;
    
    let promptText = this.promptTarget.value;
    console.log("Prompt idioma original: " + promptText);
    // Detectar el idioma

    if (!window.languageDetector) {
      console.error('languageDetector no está inicializado.');
      return;
  }

    if (window.languageDetector) {
      const result = window.languageDetector.create(promptText);
      console.log('Detección de idioma para el texto:', promptText);
      console.log('Resultado de detección:', result);
    // Si el idioma detectado es inglés, no traducir
    if (result.findLanguage(promptText).language === 'en') {
      console.log('El texto ya está en inglés');
      promptText = promptText; // Mantiene el texto original si no hay traducción
    } else {
      console.log('El texto es: ' + result.findLanguage(promptText).language);
      // Solo traducir si no está en inglés
      const translatedPrompt = await this.translateToEnglish(promptText);
      
        // Verificar si la traducción fue exitosa
    if (!translatedPrompt || translatedPrompt === "Lo siento, hubo un error al procesar tu solicitud.") 
    {
          console.warn("No se pudo traducir, usando el prompt original.");
          promptText = promptText; // Mantiene el texto original si no hay traducción
      } else {
          promptText = translatedPrompt; // Usa la traducción si fue exitosa
      }
    }
  }
    console.log("Prompt idioma inglés: " + promptText);
    
    return {
        ai_image: {
            prompt: promptText,
            model: this.modelTarget.value,
            num_variations: parseInt(this.numVariationsTarget.value, 10) || 1,
            style: this.styleTarget.value,
            aspect_ratio: (width && height) ? `${width}:${height}` : this.getCurrentAspectRatio(),
            seed: parseInt(this.seedTarget.value, 10) || null,
            guidance_scale: parseFloat(this.guidanceScaleTarget.value) || 3.5,
            num_inference_steps: parseInt(this.numInferenceStepsTarget.value, 10) || 50,
            is_public: this.isPublicTarget.checked
        }
    };
}

async translateToEnglish(text) {
  if (!text) return text;

  try {
    const response = await fetch('/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ prompt: text })
    });

    if (!response.ok) {
      console.warn('Translation request failed');
      return text;
    }

    const data = await response.json();
    console.log('Translation response:', data);

    // Verificar si hay una traducción válida
    return data.translated_text || text;
  } catch (error) {
    console.error('Translation error:', error);
    return text;
  }
}

  async sendGenerationRequest(params) {
    if (!this.hasProgressContainerTarget) {
      console.warn('Progress container no encontrado al iniciar la petición')
      // Dar tiempo para que el DOM se actualice si es necesario
      await new Promise(resolve => setTimeout(resolve, 100))
    }

    this.startProgress()
    
    try {
      const response = await fetch('/ai_images', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        body: JSON.stringify(params)
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      // Procesar primero la respuesta JSON
      const data = await response.json()

      // Procesar el turbo stream después si existe
      const turboStreamContent = response.headers.get('X-Turbo-Stream')
      if (turboStreamContent) {
        const decodedContent = decodeURIComponent(escape(turboStreamContent))
        // Procesar la respuesta JSON primero
        await this.handleJsonResponse(data)
        // Luego renderizar el turbo stream
        Turbo.renderStreamMessage(decodedContent)
      } else {
        await this.handleJsonResponse(data)
      }

    } catch (error) {
      console.error('Error en sendGenerationRequest:', error)
      this.handleError(error)
      this.cleanupAfterError()
    } finally {
      // Asegurar que stopProgress se llame después de todo
      if (this.hasProgressContainerTarget) {
        this.stopProgress()
      }
    }
  }


  cleanupAfterError() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
      this.progressInterval = null
    }

    if (this.hasProgressContainerTarget) {
      // Ocultar el contenedor de progreso
      this.progressContainerTarget.classList.remove('opacity-100')
      this.progressContainerTarget.classList.add('opacity-0')
    }
  }

  
  verifyCreditsUpdate() {
    const creditsElement = document.getElementById('credits_count')
    if (!creditsElement) {
      console.warn('Credits element not found after update')
      // Podrías intentar una actualización manual aquí si es necesario
    }
}


async handleJsonResponse(data) {
  try {
    // Verificar si el target existe antes de usarlo
    if (!this.hasProgressContainerTarget) {
      console.warn('Progress container no encontrado, esperando...')
      // Dar tiempo para que el DOM se actualice
      await new Promise(resolve => setTimeout(resolve, 100))
    }

    if (data.image_urls && data.ai_image_ids) {
      // Verificar nuevamente después de la espera
      if (this.hasProgressContainerTarget) {
        this.progressContainerTarget.classList.remove('opacity-100')
        this.progressContainerTarget.classList.add('opacity-0')
      }

      // Mantener tu lógica existente
      this.showResult(data.image_urls, data.ai_image_ids)
      this.showSuccessNotification()
      this.enableButtons()
    } else if (data.error) {
      console.error("Error en la respuesta:", data.error)
      this.showErrorNotification(data.error)
    } else {
      console.error("Respuesta inesperada:", data)
      this.showErrorNotification("Respuesta inesperada del servidor")
    }

    this.verifyCreditsUpdate()
  } catch (error) {
    console.error('Error en handleJsonResponse:', error)
    // Si hay un error accediendo a los targets, aún intentamos mostrar el error
    this.showErrorNotification("Error al procesar la respuesta")
  }
}


    // Método auxiliar para verificar y esperar targets
    async ensureTargets() {
      if (!this.hasProgressContainerTarget) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
      return this.hasProgressContainerTarget
    }

  enableButtons() {
    this.likeButtonTarget.disabled = false
    this.favoriteButtonTarget.disabled = false
    this.downloadButtonTarget.disabled = false
    this.copyButtonTarget.disabled = false
  }

  disableButtons() {
    this.likeButtonTarget.disabled = true
    this.favoriteButtonTarget.disabled = true
    this.downloadButtonTarget.disabled = true
    this.copyButtonTarget.disabled = true
  }



  likeImage() {
    if (!this.currentImageId) {
      console.error("No hay una imagen generada para dar like")
      return
    }
    fetch(`/ai_images/${this.currentImageId}/like`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.notifyTarget.innerHTML = '<p class="text-green-600">Imagen marcada como me gusta</p>'
      } else {
        this.notifyTarget.innerHTML = '<p class="text-red-600">Error al marcar como me gusta</p>'
      }
    })
    .catch(error => {
      this.notifyTarget.innerHTML = `<p class="text-red-600">Error: ${error.message}</p>`
    })
  }

  downloadImage() {
    const mainImage = this.mainImageTarget.querySelector('img');
    if (mainImage) {
      const imageUrl = mainImage.src;
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = 'generated_image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('No hay imagen principal para descargar');
    }
  }

  copyPrompt() {
    const prompt = this.promptTarget.value
    navigator.clipboard.writeText(prompt).then(() => {
      this.copyNotificationTarget.classList.remove('hidden')
      setTimeout(() => {
        this.copyNotificationTarget.classList.add('hidden')
      }, 2000)
    }).catch(err => {
      console.error('Error al copiar el prompt: ', err)
    })
  }

  showErrorNotification(message) {
    setTimeout(() => {
      this.notifyTarget.innerHTML = `
      <p class="error text-red-600">Error: ${message}</p>`
    }, 2000)
   
  }

    showSuccessNotification() {
    this.notifyTarget.innerHTML = `
      <div class="mt-2 text-green-600 flex items-center justify-center">
        <svg class="h-6 w-6 mr-2" fill="none" stroke-linecap="round" 
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M5 13l4 4L19 7"></path>
        </svg>
        Imágenes generadas con éxito
      </div>`
  }



  handleError(error) {
    console.error('Handling error:', error)
    // Mostrar mensaje de error al usuario (implementa según tu UI)
    if (error.message.includes('422')) {
      // Manejar error de validación
      this.showErrorNotification(error)
      this.showErrorMessage('Failed to open TCP connection, intente nuevamente')
    } else {
      this.showErrorMessage('Ocurrió un error durante la generación. Por favor intente nuevamente.')
      this.showErrorNotification(error)
    }
  }

  saveToFavorites() {
    if (!this.currentImageId) {
      console.error("No hay una imagen generada para guardar en favoritos")
      return
    }
    fetch(`/ai_images/${this.currentImageId}/favorite`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.notifyTarget.innerHTML = '<p class="text-green-600">Imagen guardada en favoritos</p>'
      } else {
        this.notifyTarget.innerHTML = '<p class="text-red-600">Error al guardar en favoritos</p>'
      }
    })
    .catch(error => {
      this.notifyTarget.innerHTML = `<p class="text-red-600">Error: ${error.message}</p>`
    })
  }


 

  stopLoading() {
    this.generateButtonTarget.disabled = false
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.add("hidden")
    }
    if (this.hasImageContainerTarget) {
      this.imageContainerTarget.classList.remove("bg-gray-100")
    }
  }



  showAllImages() {
    // Implementa la lógica para mostrar todas las imágenes generadas
    console.log("Mostrar todas las imágenes")
  }

  showFullImage() {
    window.open(this.resultTarget.src, "_blank")
  }

  startProgress() {
    if (!this.hasProgressContainerTarget) return
    
    this.currentProgress = 0
    this.updateProgress(0)
    
    // Usar requestAnimationFrame para animaciones más suaves
    this.progressInterval = setInterval(() => {
      this.currentProgress = Math.min(this.currentProgress + 1, 90)
      this.updateProgress(this.currentProgress)
    }, 100)
  }



  endProgress() {
    this.progressContainerTarget.classList.add('invisible');
  }

  stopProgress() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
      this.progressInterval = null
    }
    
    if (!this.hasProgressContainerTarget) return
    
    // Animación final hasta 100%
    this.updateProgress(100)
    
    // Ocultar progresivamente
    setTimeout(() => {
      if (this.hasProgressContainerTarget) {
        this.progressContainerTarget.classList.remove('opacity-100')
        this.progressContainerTarget.classList.add('opacity-0')
      }
    }, 500)
  }

  updateProgress(percentage) {
    if (!this.hasProgressCircleTarget || !this.hasProgressTextTarget) return
    
    const rotation = percentage * 3.6
    const clipValue = `polygon(50% 50%, -50% 50%, -50% -50%, 50% -50%, 50% 50%, ${percentage}% 50%)`
    
    requestAnimationFrame(() => {
      this.progressCircleTarget.style.transform = `rotate(${rotation}deg)`
      this.progressCircleTarget.style.clipPath = clipValue
      this.progressTextTarget.textContent = `${Math.round(percentage)}%`
    })
  }
  stopLoading() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
      this.progressInterval = null
    }

    // Animación suave hasta 100%
    this.updateProgress(100)

    // Ocultar usando transform y opacity
    setTimeout(() => {
      this.progressContainerTarget.classList.remove('scale-100', 'opacity-100')
      this.progressContainerTarget.classList.add('scale-0', 'opacity-0')
    }, 700)
  }

  // Este método lo puedes llamar cuando comience la generación de imagen
 
  



  showResult(imageUrls, imageIds) {
    this.currentImageIds = imageIds;
    this.imageContainerTarget.classList.remove("hidden");

    const mainImageContainer = this.mainImageTarget;
    const thumbnailContainers = this.thumbnailContainerTargets;

    // Limpiar el contenedor de la imagen principal
    mainImageContainer.innerHTML = ''; 

    // Mostrar la primera imagen ampliada
    if (imageUrls.length > 0) {
      this.showMainImage(imageUrls[0]);
    }

    // Limpiar y actualizar las miniaturas
    thumbnailContainers.forEach((container, index) => {
      container.innerHTML = ''; // Limpiar el contenedor
      if (index < imageUrls.length) {
        const thumbnailElement = this.createThumbnailElement(imageUrls[index], index);
        container.appendChild(thumbnailElement);
      } else {
        // Crear un placeholder para las miniaturas no utilizadas
        const placeholderElement = this.createPlaceholderElement();
        container.appendChild(placeholderElement);
      }
    });
  }

  createThumbnailElement(url, index) {
    const thumbnailElement = document.createElement('img');
    thumbnailElement.src = url;
    thumbnailElement.alt = `Miniatura ${index + 1}`;
    thumbnailElement.classList.add('w-full', 'h-full', 'object-cover', 'rounded-lg', 'hover:opacity-80', 'transition', 'duration-300', 'cursor-pointer');

    if (index === 0) {
      thumbnailElement.classList.add('border-2', 'border-blue-500');
    }

    thumbnailElement.onclick = () => {
      this.showMainImage(url);
      this.updateThumbnailSelection(thumbnailElement);
    };

    return thumbnailElement;
  }

  updateThumbnailSelection(selectedThumbnail) {
    this.thumbnailContainerTargets.forEach((container) => {
      const thumbnail = container.querySelector('img');
      if (thumbnail) {
        thumbnail.classList.remove('border-2', 'border-blue-500');
      }
    });
    selectedThumbnail.classList.add('border-2', 'border-blue-500');
  }

  createPlaceholderElement() {
    const placeholderElement = document.createElement('div');
    placeholderElement.classList.add('w-full', 'h-full', 'bg-gray-200', 'rounded-lg');
    return placeholderElement;
  }

  showMainImage(imageUrl) {
    const mainImageContainer = this.mainImageTarget;
    mainImageContainer.innerHTML = ''; // Limpiar la imagen actual
  
    const imageElement = document.createElement('img');
    imageElement.src = imageUrl;
    imageElement.alt = 'Imagen ampliada';
    imageElement.classList.add('w-full', 'h-auto', 'object-contain', 'rounded-lg');
    mainImageContainer.appendChild(imageElement);
  }


  disconnect() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
    }
  }


}