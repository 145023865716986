import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    debug: { type: Boolean, default: true },
    modelEndpoint: { type: String, default: "fffiloni/diffusers-image-outpaint" },
    timeoutDuration: { type: Number, default: 180000 },
    timeout: { type: Number, default: 30000 }
  }

  static targets = [
    "imageInput", 
    "promptInput", 
    "submitButton", 
    "preview", 
    "dropZone",
    "status",
    "progressBar",
    "resultSection",
    "resultImage",
    "aspectRatioInput",
    "overlapPercentageInput",
    "numInferenceStepsInput",
    "resizeOptionInput",
    "customResizePercentageInput",
    "alignmentInput",
    "overlapLeftInput",
    "overlapRightInput",
    "overlapTopInput",
    "overlapBottomInput",
    "status",
    'form',
    "slider", 
    "divider",    
    "uploadContent"
  ];

  async connect() {
    await this.initializeGradio()
  }

  async initializeGradio() {
    try {
      while (!window.GradioClient) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
      
      this.updateStatus("Conectando ...")
      this.gradioClient = await window.GradioClient(this.modelEndpointValue)
      this.updateStatus("¡Conexión exitosa!")
        // Configurar el área de arrastrar y soltar
        this.setupDropZone();
    } catch (error) {
      console.error("Error:", error);
      this.updateStatus(`Error de conexión: ${error.message}`, true)
    }
    this.isDragging = false
    this.boundHandleDragging = this.handleDragging.bind(this)
    this.boundStopDragging = this.stopDragging.bind(this)
  }




  updateStatus(message, isError = false) {
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = message;
      this.statusTarget.classList.toggle('error', isError);
    }
  }

  setupDropZone() {
    this.dropZoneTarget.addEventListener('dragover', (e) => {
      e.preventDefault();
      this.dropZoneTarget.classList.add('border-indigo-500');
    });

    this.dropZoneTarget.addEventListener('dragleave', (e) => {
      e.preventDefault();
      this.dropZoneTarget.classList.remove('border-indigo-500');
    });

    this.dropZoneTarget.addEventListener('drop', (e) => {
      e.preventDefault();
      this.dropZoneTarget.classList.remove('border-indigo-500');
      
      const file = e.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.imageInputTarget.files = e.dataTransfer.files;
        this.previewImage(file);
      }
    });
  }

  handleImageSelect(event) {
    const file = event.target.files[0];
    console.log("Archivo seleccionado:", file);
    if (this.validateFile(file)) {
      this.previewImage(file);
    }
  }

  validateFile(file) {
    if (!file) return false;

    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validTypes.includes(file.type)) {
      this.showError("Por favor selecciona una imagen PNG o JPG o Webp");
      return false;
    }

    if (file.size > maxSize) {
      this.showError("La imagen debe ser menor a 5MB");
      return false;
    }

    return true;
  }

  previewImage(file) {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      const img = this.previewTarget.querySelector('img');
      img.src = e.target.result;
      this.previewTarget.classList.remove('hidden');
    };
    
    reader.readAsDataURL(file);
  }

    // Método auxiliar para preparar los parámetros
    prepareParams(event) {
      // Obtener la imagen del input file
      const imageInput = this.imageInputTarget;
      const imageFile = imageInput.files[0];
    
      // Obtener valores de los targets
      const params = {
        image: imageFile,
        aspect_ratio: this.aspectRatioInputTarget.value,
        prompt: this.promptInputTarget.value,
        overlap_percentage: parseFloat(event.target.elements['ai_image[overlap_percentage]'].value),
        num_inference_steps: parseInt(event.target.elements['ai_image[num_inference_steps]'].value),
        resize_option: event.target.elements['ai_image[resize_option]'].value,
        custom_resize_percentage: parseFloat(event.target.elements['ai_image[custom_resize_percentage]'].value),
        alignment: event.target.elements['ai_image[alignment]'].value,
        overlap_left: this.overlapLeftInputTarget.checked,
        overlap_right: this.overlapRightInputTarget.checked,
        overlap_top: this.overlapTopInputTarget.checked,
        overlap_bottom: this.overlapBottomInputTarget.checked
      };
    
      // Log para debugging
      console.log("=== PARAMS DESDE STIMULUS TARGETS ===", params);
      return params;
    }

// Método auxiliar para convertir File a base64
async fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
 
async submitForm(event) {
  event.preventDefault();
  
    // Deshabilitar el botón y mostrar loading
    if (this.SubmitButtonTarget) {
      this.SubmitButtonTarget.disabled = true
    }
    
    // Mostrar el skeleton loader
    this.showLoading()


  try {
    this.updateStatus("Procesando imagen...");
    this.submitButtonTarget.disabled = true;
  
    // Obtener parámetros usando los targets de Stimulus
    const params = this.prepareParams(event);
    
    console.log("Mustrando la variable params linea 179 ")
    console.log(JSON.stringify(params));
    
    if (!params) {
      throw new Error('Error al preparar los parámetros');
    }

    // Crear FormData para el backend de Rails
    const preparedFormData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        preparedFormData.append(`outpainting[${key}]`, value);
      }
    });

    // Enviar al endpoint de prepare_params
    const paramsResponse = await fetch('/outpaintings/prepare_params', {
      method: 'POST',
      body: preparedFormData,
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': this.getCSRFToken()
      }
    });

    if (!paramsResponse.ok) {
      const errorData = await paramsResponse.json();
      console.error("Error en prepare_params:", errorData);
      throw new Error(errorData.error || 'Error preparando parámetros');
    }

    console.log(JSON.stringify(paramsResponse));
    
    const responseData = await paramsResponse.json();
    
    if (!responseData) {
      throw new Error('No se recibieron datos del servidor');
    }

    // Preparar parámetros para 
    const gradioInput = {
      image: params.image,
      width: parseInt(params.width || 1024),
      height: parseInt(params.height || 576),
      overlap_percentage: parseFloat(params.overlap_percentage || 1),
      num_inference_steps: parseInt(params.num_inference_steps || 20),
      resize_option: params.resize_option || "Full",
      custom_resize_percentage: parseFloat(params.custom_resize_percentage || 1),
      prompt_input: params.prompt || "",
      alignment: params.alignment || "Middle",
      overlap_left: Boolean(params.overlap_left),
      overlap_right: Boolean(params.overlap_right),
      overlap_top: Boolean(params.overlap_top),
      overlap_bottom: Boolean(params.overlap_bottom)
    };

    console.log("Parámetros...:", gradioInput);
    
    // Llamar a la API de Gradio
    const result = await this.makeRequest(gradioInput);
    console.log(result)
    if (!result || !result.data || !result.data[0][1]) {
      throw new Error('Respuesta inválida');
    }

    const generatedImageUrl = result.data[0][1].url;
    const original_image_url = result.data[0][0].url;
    try {
      console.log("data_Imagen:" + result.data[0][1].url);
      const imageResponse = await fetch(generatedImageUrl);
      console.log("imagen:", imageResponse);
      
      if (!imageResponse.ok) {
        throw new Error('Error al obtener la imagen generada de Gradio');
      }
      
      const imageBlob = await imageResponse.blob();
      const imageFile = new File([imageBlob], 'outpainting-result.png', { type: 'image/png' });

     
      const saveFormData = new FormData();
      
      // Agregar todos los parámetros permitidos
      const paramsToSave = {
        prompt: params.prompt,
        aspect_ratio: params.aspect_ratio,
        is_public: params.is_public || false,
        overlap_percentage: params.overlap_percentage,
        resize_option: params.resize_option,
        custom_resize_percentage: params.custom_resize_percentage,
        alignment: params.alignment,
        overlap_left: params.overlap_left,
        overlap_right: params.overlap_right,
        overlap_top: params.overlap_top,
        overlap_bottom: params.overlap_bottom,
        original_image_url: original_image_url,
        generated_image_url: generatedImageUrl
      };
      
      // Agregar parámetros al FormData
      Object.entries(paramsToSave).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          if (typeof value === 'boolean') {
            saveFormData.append(`outpainting[${key}]`, value ? "1" : "0");
          } else {
            saveFormData.append(`outpainting[${key}]`, value.toString());
          }
        }
      });
      
      // Agregar las imágenes
      if (params.image) {
        saveFormData.append('outpainting[original_image]', params.image);
        console.log("Imagen original adjunta STIMULKUSS:_:", params.image);
      }

      if (imageFile) {
        saveFormData.append('outpainting[processed_image]', imageFile);
        console.log("Imagen procesada adjunta PPROCESSED IMAGE:", imageFile);
      }
      
      
      const createUrl = '/outpaintings';
      
      // Obtener el token CSRF
      const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
      if (!csrfToken) {
        throw new Error('No se pudo encontrar el token CSRF');
      }
      
      // Enviar al create con los headers corregidos
      const saveResponse = await fetch(createUrl, {
        method: 'POST',
        body: saveFormData,
        headers: {
          'Accept': 'text/vnd.turbo-stream.html, application/json, text/html, application/xhtml+xml',
          'X-CSRF-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest'
        },
        credentials: 'same-origin'
      });
      
      if (!saveResponse.ok) {
        const errorText = await saveResponse.text();
        console.error('Error Response:', errorText);
        throw new Error(`Error guardando la imagen: ${saveResponse.status} ${saveResponse.statusText}`);
      }
      
      const html = await saveResponse.text();
      console.log("Respuesta del servidor:", html);
      
      // Verificar si la respuesta es un Turbo Stream válido o JSON
      if (html.trim().startsWith('<turbo-stream')) {
        Turbo.renderStreamMessage(html);
        this.updateStatus("¡Imagen guardada exitosamente!");
        this.showResult();
      } else {
        try {
          // Intentar parsear como JSON por si acaso
          const jsonResponse = JSON.parse(html);
          if (jsonResponse.success) {
            this.updateStatus("¡Imagen guardada exitosamente!");
            this.showResult();
          } else {
            throw new Error(jsonResponse.error || 'Error desconocido');
          }
        } catch (e) {
          if (html.includes('error')) {
            throw new Error(html);
          }
          // Si no es JSON ni contiene error, asumimos éxito
          this.updateStatus("¡Imagen guardada exitosamente!");
          this.showResult();
        }
      }
      
    } catch (error) {
      console.error('Error en el proceso de guardado:', error);
      this.updateStatus(`Error: ${error.message}`, true);
    }
  } catch (error) {
    console.error("Error:", error);
    this.updateStatus(`Error: ${error.message}`, true);
  } finally {
    if (this.SubmitButtonTarget) {
      this.SubmitButtonTarget.disabled = false
    }
  }
}


async makeRequest(gradioInput) {


  // Verificar que gradioClient esté inicializado
  if (!this.gradioClient) {
    console.error('Client no está inicializado');
    throw new Error('Cliente no inicializado. Verifique la configuración.');
  }

  // Verificar que todos los elementos del input estén presentes
  const requiredParams = [
    'image',
    'width',
    'height',
    'overlap_percentage',
    'num_inference_steps',
    'resize_option',
    'custom_resize_percentage',
    'prompt_input',
    'alignment',
    'overlap_left',
    'overlap_right',
    'overlap_top',
    'overlap_bottom'
  ];


  const missingParams = requiredParams.filter(param => gradioInput[param] === undefined);
  if (missingParams.length > 0) {
    throw new Error(`Faltan parámetros requeridos: ${missingParams.join(', ')}`);
  }

  // Crear el controlador de aborto
  const controller = new AbortController();
  const signal = controller.signal;
  
  // Configurar el timeout
  const timeoutId = setTimeout(() => {
    controller.abort();
    this.logMessage('La petición fue abortada por timeout', 'warn');
  }, this.timeoutValue);

  try {
    this.logMessage("Iniciando petición...");
    console.log("Input:", gradioInput);
    console.log("Iniciando petición...");
    for (const prop in this.gradioClient) {
      console.log(prop + ": " + this.gradioClient[prop]);
    }
    this.showProgressBar();
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = "Procesando...";
    }

    // Verificar que el método predict existe
    if (typeof this.gradioClient.predict !== 'function') {
      throw new Error('El método predict no está disponible en el cliente');
    }

    // Intentar la predicción con logging detallado
    console.log("Iniciando predict...");
    const result = await Promise.race([
      (async () => {
        try {
          const predictionResult = await this.gradioClient.predict("/infer", gradioInput);
          console.log("Resultado de predicción:", predictionResult);
          return predictionResult;
        } catch (predictionError) {
          console.error("Error durante la predicción:", predictionError);
          throw predictionError;
        }
      })(),
      new Promise((_, reject) => 
        setTimeout(() => 
          reject(new Error(`La petición excedió el tiempo límite de ${this.timeoutValue/1000} segundos`)), 
          this.timeoutValue
        )
      )
    ]);

    // Validación del resultado
    if (!result) {
      throw new Error('La respuesta está vacía');
    }

    // Verificar la estructura del resultado
    if (!result.data) {
      console.error("Estructura de resultado inválida:", result);
      throw new Error('Formato de respuesta inválido');
    }

    this.logMessage("Petición completada exitosamente");
    console.log("Resultado completo:", result);
    
    if (this.hasResultTarget) {
      this.resultTarget.textContent = JSON.stringify(result);
    }

    return result;

  } catch (error) {
    let errorMessage;
    
    // Manejo de errores más detallado
    if (error.name === 'AbortError') {
      errorMessage = 'La petición fue cancelada por timeout';
    } else if (error.message && error.message.includes('timeout')) {
      errorMessage = `Timeout: La petición no se completó en ${this.timeoutValue/1000} segundos`;
    } else if (error.response) {
      // Error de respuesta HTTP
      errorMessage = `Error HTTP ${error.response.status}: ${error.response.statusText}`;
      console.error("Detalles del error HTTP:", await error.response.text());
    } else if (error.message) {
      errorMessage = `Error en la petición: ${error.message}`;
    } else {
      errorMessage = 'Error desconocido durante la petición';
      console.error("Error completo:", error);
    }

    this.logMessage(errorMessage, 'error');
    console.error("Error detallado:", {
      error,
      gradioInput,
      timeoutValue: this.timeoutValue
    });
    
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = "Error en la petición";
    }

    throw new Error(errorMessage);

  } finally {
    clearTimeout(timeoutId);
    if (!signal.aborted) {
      controller.abort();
    }
  }
}

  // Método auxiliar para logging
  logMessage(message, type = 'log') {
    const prefix = '[Gradio Request]';
    switch(type) {
      case 'error':
        console.error(`${prefix} ${message}`);
        break;
      case 'warn':
        console.warn(`${prefix} ${message}`);
        break;
      default:
        console.log(`${prefix} ${message}`);
    }
  }
  removeImage(event) {
    this.previewTarget.classList.add('hidden')
    this.uploadContentTarget.classList.remove('hidden')
    this.imageInputTarget.value = '' // Limpia el input
    // Si tienes la imagen en un target, también puedes limpiarla
    this.previewTarget.querySelector('img').src = ''
  }



  async callGradio(params) {
    return await Promise.race([
      this.gradioClient.predict("/infer", params),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Timeout: La petición tardó demasiado")), 
        this.timeoutDurationValue)
      )
    ])
  }





  validateForm() {
    if (!this.imageInputTarget.files[0]) {
      this.showError("Por favor selecciona una imagen");
      return false;
    }

    if (!this.promptInputTarget.value.trim()) {
      this.showError("Por favor ingresa una descripción para el outpainting");
      return false;
    }
  }

  showError(message) {
    this.statusTarget.innerHTML = `
      <div class="rounded-md bg-red-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-red-800">
              ${message}
            </p>
          </div>
        </div>
      </div>
    `
  }


    // Método auxiliar para procesar el archivo de imagen
    async processImageFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(file); // Aquí cambiamos para devolver el file directamente
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }
  

    // Método auxiliar para convertir aspect ratio en dimensiones
    getDimensionsFromAspectRatio(aspectRatio) {
      const baseSize = 720;
      
      switch (aspectRatio) {
        case '1:1':
          return [baseSize, baseSize];
        case '16:9':
          return [baseSize, Math.round(baseSize * 9/16)];
        case '9:16':
          return [Math.round(baseSize * 16/9), baseSize];
        default:
          return [baseSize, baseSize];
      }
    }


    showResult(result) {
      // Este método ya no necesita crear el HTML ya que será manejado por el partial
      this.resultSectionTarget.classList.remove('hidden')
    }

    showLoading() {
      if (this.hasResultSectionTarget) {
        this.resultSectionTarget.classList.remove('hidden')
        this.resultImageTarget.innerHTML = this.skeletonTemplate
      }
    }

    get skeletonTemplate() {
      return `
        <div class="relative w-full h-[400px] aspect-square ">
          <div class="">
            <!-- Fondo skeleton -->
            <div class="absolute inset-0 bg-gray-200">
              <div class="absolute inset-0 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-[shimmer_2s_infinite]"></div>
            </div>
            
            <!-- Línea divisoria skeleton -->
            <div class="absolute top-0 bottom-0 left-1/2 w-1 bg-gray-300"></div>
            
            <!-- Control deslizante skeleton -->
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div class="w-8 h-8 bg-gray-300 rounded-full shadow"></div>
            </div>
          </div>
        </div>
      `
    }

    resetResult() {
      this.resultSectionTarget.classList.add('hidden')
    }

    showResufflt(result) {
      const originalImageUrl = result.data[0][0].url;
      const generatedImageUrl = result.data[0][1].url;
      
      this.resultImageTarget.innerHTML = `
        <div class="p-4 bg-indigo-400/60 rounded">
          <h3 class="font-bold mb-4">Comparación de Imágenes</h3>
          
          <div class="relative w-full max-w-3xl mx-auto">
            <div class="relative w-full h-[400px] aspect-square overflow-hidden"
                 data-action="mousemove->outpainting#handleDragging 
                              mouseup->outpainting#stopDragging
                              touchmove->outpainting#handleDragging 
                              touchend->outpainting#stopDragging">
              <!-- Imagen Original (Fondo) -->
              <img 
                src="${originalImageUrl}" 
                alt="Imagen Original" 
                class="absolute top-0 left-0 w-full h-full object-cover"
              >
              
              <!-- Contenedor de la imagen generada con clip-path -->
              <div class="absolute top-0 left-0 w-full h-full" 
                   style="clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);"
                   data-outpainting-target="slider">
                <img 
                  src="${generatedImageUrl}" 
                  alt="Imagen Generada" 
                  class="absolute top-0 left-0 w-full h-full object-cover"
                >
              </div>
              
              <!-- Línea divisoria y control deslizante -->
              <div class="absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize select-none"
                   style="left: 50%;"
                   data-outpainting-target="divider"
                   data-action="mousedown->outpainting#startDragging 
                              touchstart->outpainting#startDragging">
                <!-- Círculo del control deslizante -->
                <div class="absolute top-1/2 left-1/2 w-8 h-8 -ml-4 -mt-4 bg-white rounded-full shadow-lg flex items-center justify-center">
                  <svg class="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h8M8 12h8M8 17h8"></path>
                  </svg>
                </div>
              </div>
            </div>
            
            <div class="mt-4 flex justify-center">
              <button type="button"
                      class="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
                      data-action="click->outpainting#downloadImage">
                Descargar imagen
              </button>
            </div>
          </div>
        </div>
      `
      
      this.resultSectionTarget.classList.remove('hidden');
    }
  
    startDragging(event) {
      this.isDragging = true
      event.preventDefault()
    }
  
    stopDragging() {
      this.isDragging = false
    }
  
    handleDragging(event) {
      if (!this.isDragging) return
  
      const containerRect = event.currentTarget.getBoundingClientRect()
      const x = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX
      let position = ((x - containerRect.left) / containerRect.width) * 100
      
      // Limitar la posición entre 0 y 100
      position = Math.max(0, Math.min(100, position))
      
      // Actualizar la posición del divisor y el clip-path
      this.dividerTarget.style.left = `${position}%`
      this.sliderTarget.style.clipPath = `polygon(0 0, ${position}% 0, ${position}% 100%, 0 100%)`
    }
  
    downloadImage(event) {
      event.preventDefault();
      const url = this.dataset.url; // Obtener URL desde data attribute
      
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'outpainting-result.png';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => console.error('Error descargando imagen:', error));
    }
  
    disconnect() {
      this.isDragging = false
    }

  updateStatus(message, isError = false) {
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = message;
      this.statusTarget.className = isError ? 
        'text-red-500' : 
        'text-green-500';
    }
  }

  getCSRFToken() {
    return document.querySelector('meta[name="csrf-token"]')?.content
  }

  showStatus(message) {
    this.statusTarget.innerHTML = `
      <div class="rounded-md bg-blue-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-blue-800">
              ${message}
            </p>
          </div>
        </div>
      </div>
    `
  }

  showProgressBar() {
    this.progressBarTarget.classList.remove('hidden');
    const progressBar = this.progressBarTarget.querySelector('.bg-blue-600');
    let width = 0;
    const interval = setInterval(() => {
      if (width >= 100) {
        clearInterval(interval);
      } else {
        width += 10;
        progressBar.style.width = width + '%';
      }
    }, 500);
  }

  hideProgressBar() {
    this.progressBarTarget.classList.add('hidden');
  }


}