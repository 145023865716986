// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic 
// require("turbolinks").start()
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "@hotwired/turbo-rails"
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")
require('custom/home')

import 'controllers'
import 'channels'
import "prismjs";

import { loadModule } from 'cld3-asm';

window.loadCLD3 = async () => {
    try {
        console.log('Intentando cargar CLD3');
        
        // Load the CLD3 module
        window.languageDetector = await loadModule();
        console.log('CLD3 cargado');

        // List available methods
        console.log('Métodos disponibles:', Object.keys(window.languageDetector));

        // Function to detect language
        const detectLanguage = (text) => {
          if (typeof text !== 'string') {
              console.error('El texto proporcionado no es una cadena de texto');
              return;
          }
      
          const result = window.languageDetector.create(text);
          console.log('Detección de idioma para el texto:', text);
          console.log('Resultado de detección NEW:', result.findLanguage(text).language);
      
          try {
              const languageResult = result.findLanguage(text);
              console.log('Resultado de detección:', languageResult.language);
              return languageResult;
          } catch (error) {
              console.error('Error al detectar el idioma:', error);
              console.error('Tipo de error:', error.name);
              console.error('Mensaje de error:', error.message);
          }
      };

       
        // Test with sample texts
        detectLanguage('Hola, gracias por visitarme como estas tu, Si el texto que estás utilizando es muy corto, la biblioteca podría tener dificultades para detectar el idioma correctamente. Intenta con un texto más largo y descriptivo');
    } catch (error) {
        console.error('Error detallado:', error);
        console.error('Tipo de error:', error.name);
        console.error('Mensaje de error:', error.message);
    }
};

// Call the method to load CLD3
window.loadCLD3();
//import "prismjs/themes/prism-tomorrow.css"; // Tema oscuro con colores intensos
