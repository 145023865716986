// app/javascript/controllers/swiper_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  
  async connect() {
    console.log("Swiper controller connecting...")
    
    if (!this.hasContainerTarget) {
      console.error("No se encontró el target del contenedor")
      return
    }
    
    if (!this.isSwiperLoaded()) {
      console.log("Swiper no detectado, cargando desde CDN...")
      await this.loadSwiperCDN()
    }
    
    try {
      await this.initializeSwiper()
      console.log("Swiper inicializado exitosamente")
    } catch (error) {
      console.error("Error al inicializar Swiper:", error)
    }
  }

  isSwiperLoaded() {
    return typeof window.Swiper !== 'undefined'
  }

  async loadSwiperCDN() {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = resolve
        script.onerror = reject
        document.head.appendChild(script)
      })
    }

    const loadCSS = (href) => {
      return new Promise((resolve, reject) => {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = href
        link.onload = resolve
        link.onerror = reject
        document.head.appendChild(link)
      })
    }

    try {
      await loadCSS('https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css')
      await loadScript('https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js')
      await this.waitForSwiper()
    } catch (error) {
      console.error("Error cargando recursos de Swiper:", error)
      throw error
    }
  }

  async waitForSwiper(timeout = 5000) {
    const startTime = Date.now()
    
    while (!window.Swiper) {
      if (Date.now() - startTime > timeout) {
        throw new Error("Timeout esperando a Swiper")
      }
      await new Promise(resolve => setTimeout(resolve, 100))
    }
  }

  async initializeSwiper() {
    if (!window.Swiper) {
      throw new Error("Swiper no está disponible")
    }

    this.swiper = new Swiper(this.containerTarget, {
      // Configuración básica del slider
      slidesPerView: 1,
      spaceBetween: 1,
      centeredSlides: false,
      speed: 2000,
      
      // Configuración del loop
      loop: true,
      loopedSlides: 8,
      loopFillGroupWithBlank: false,
      loopPreventsSlide: false,
      
      // Configuración de efectos y comportamiento
      effect: 'slide',
      watchOverflow: true,
      observer: true,
      observeParents: true,
      grabCursor: true,
      
      // Configuración del autoplay
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      
      // Configuración de la paginación
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 3
      },
      
      // Configuración de la navegación
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        enabled: true
      },
      
      // Breakpoints responsivos
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 0
        }
      }
    })
  }

  prev() {
    if (this.swiper) {
      this.swiper.slidePrev({
        speed: 600,
      })
    }
  }

  next() {
    if (this.swiper) {
      this.swiper.slideNext({
        speed: 600,
      })
    }
  }


  disconnect() {
    if (this.swiper) {
      this.swiper.destroy(true, true)
      this.swiper = null
    }
  }
}