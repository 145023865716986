// app/javascript/controllers/image_generador_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    // Al cargar, mostrar las imágenes de la primera categoría (ilustración)
    this.showImages('illustration');
  }

  generateImages(event) {
    const type = event.currentTarget.dataset.imageGeneradorType;

    // Cambiar el estado activo del botón
    this.updateActiveButton(event.currentTarget);

    // Mostrar las imágenes correspondientes
    this.showImages(type);
  }

  showImages(type) {
    // Ocultar todas las imágenes
    const allImages = this.containerTarget.querySelectorAll('img');
    allImages.forEach(img => img.classList.add('hidden'));

    // Mostrar solo las imágenes de la categoría seleccionada
    const selectedImages = this.containerTarget.querySelectorAll(`.${type}`);
    selectedImages.forEach(img => img.classList.remove('hidden'));
  }

  updateActiveButton(activeButton) {
    // Obtener todos los botones
    const buttons = this.element.querySelectorAll('button');

    // Desactivar todos los botones y quitar clases de estilo activo
    buttons.forEach(button => {
      button.classList.remove('bg-indigo-600', 'text-white');
      button.classList.add('bg-gray-200');
    });

    // Activar el botón clickeado
    activeButton.classList.remove('bg-gray-200');
    activeButton.classList.add('bg-indigo-600', 'text-white');
  }
}