import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    debug: { type: Boolean, default: true },
    modelEndpoint: { type: String, default: "jasperai/Flux.1-dev-Controlnet-Upscaler" },
    timeoutDuration: { type: Number, default: 180000 },
    timeout: { type: Number, default: 30000 }
  }

  static targets = [
    "imageInput",
    "promptInput",
    "submitButton",
    "preview",
    "dropZone",
    "status",
    "progressBar",
    "resultSection",
    "resultImage"
  ];

  connect() {
    this.setupDropZone();
    this.setupEventListeners();
    
    if (this.debugValue) {
      console.log("Upscale controller connected");
    }
  }

  setupEventListeners() {
    // Add event listener to submit button to prevent default form submission
    this.submitButtonTarget.addEventListener('click', (event) => {
      event.preventDefault();
      this.submitForm();
    });
  }

  submitForm() {
    // Validate inputs before submission
    if (!this.validateInputs()) {
      return;
    }

    // Show loading state
    this.updateStatus('Preparando imagen para upscale...', 'info');
    this.showProgressBar();

    // Prepare form data
    this.prepareParams((params) => {
      // Perform the actual submission
      this.performUpscale(params);
    });
  }

  validateInputs() {
    const imageFile = this.imageInputTarget.files[0];
    const prompt = this.promptInputTarget.value.trim();

    if (!imageFile) {
      this.updateStatus('Por favor, selecciona una imagen', 'error');
      return false;
    }

    if (!this.validateFile(imageFile)) {
      return false;
    }

    if (!prompt) {
      this.updateStatus('Por favor, proporciona una descripción para el upscale', 'error');
      return false;
    }

    return true;
  }

  prepareParams(callback) {
    const imageInput = this.imageInputTarget;
    const imageFile = imageInput.files[0];
  
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);
  
    img.onload = () => {
      const width = img.width;
      const height = img.height;
  
      const params = new FormData();
      params.append('control_image', imageFile);
      params.append('prompt', this.promptInputTarget.value);
      params.append('controlnet_conditioning_scale', 0.6);
      params.append('num_inference_steps', 28);
      params.append('guidance_scale', 3.5);
      params.append('height', height);
      params.append('width', width);
  
      if (this.debugValue) {
        console.log("Prepared Upscale Params:", Object.fromEntries(params));
      }
  
      URL.revokeObjectURL(img.src);
      
      callback(params);
    };
  
    img.onerror = () => {
      this.updateStatus('Error al procesar la imagen', 'error');
      console.error("Error loading image");
    };
  }
  
  async performUpscale(params) {
    try {
      const response = await fetch('/upscale/prepare_params', {
        method: 'POST',
        body: params,
        headers: {
          'X-CSRF-Token': this.getCsrfToken()
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error en la solicitud de upscale: ${errorData.error}`);
      }
  
      const result = await response.json();
      
      this.handleUpscaleSuccess(result);
    } catch (error) {
      this.handleUpscaleError(error);
    }
  }
  

  handleUpscaleSuccess(result) {
    this.updateStatus('Upscale completado con éxito', 'success');
    this.hideProgressBar();

    // Update result image if target exists
    if (this.hasResultImageTarget) {
      this.resultImageTarget.src = result.upscaled_image_url;
      this.resultSectionTarget.classList.remove('hidden');
    }
  }

  handleUpscaleError(error) {
    console.error('Upscale Error:', error);
    this.updateStatus('Error en el upscale: ' + error.message, 'error');
    this.hideProgressBar();
  }

  validateFile(file) {
    const validTypes = ['image/jpeg', 'image/png'];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!validTypes.includes(file.type)) {
      this.updateStatus('Tipo de archivo no soportado', 'error');
      return false;
    }

    if (file.size > maxSize) {
      this.updateStatus('El archivo es demasiado grande (máximo 5MB)', 'error');
      return false;
    }

    return true;
  }

  setupDropZone() {
    const dropZone = this.dropZoneTarget;

    dropZone.addEventListener('dragover', (e) => {
      e.preventDefault();
      dropZone.classList.add('border-indigo-500');
    });

    dropZone.addEventListener('dragleave', (e) => {
      e.preventDefault();
      dropZone.classList.remove('border-indigo-500');
    });

    dropZone.addEventListener('drop', (e) => {
      e.preventDefault();
      dropZone.classList.remove('border-indigo-500');

      const file = e.dataTransfer.files[0];
      if (this.validateFile(file)) {
        this.imageInputTarget.files = e.dataTransfer.files;
        this.previewImage(file);
      }
    });
  }

  handleImageSelect(event) {
    const file = event.target.files[0];
    
    if (this.debugValue) {
      console.log("Archivo seleccionado:", file);
    }

    if (this.validateFile(file)) {
      this.previewImage(file);
    }
  }

  previewImage(file) {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      const img = this.previewTarget.querySelector('img');
      img.src = e.target.result;
      this.previewTarget.classList.remove('hidden');
    };
    
    reader.readAsDataURL(file);
  }

  removeImage() {
    // Reset image input and preview
    this.imageInputTarget.value = '';
    this.previewTarget.classList.add('hidden');
    this.previewTarget.querySelector('img').src = '';
  }

  updateStatus(message, type = 'info') {
    const statusTarget = this.statusTarget;
    statusTarget.textContent = message;
    
    // Remove previous status classes
    statusTarget.classList.remove('text-green-600', 'text-red-600', 'text-blue-600');
    
    switch (type) {
      case 'success':
        statusTarget.classList.add('text-green-600');
        break;
      case 'error':
        statusTarget.classList.add('text-red-600');
        break;
      default:
        statusTarget.classList.add('text-blue-600');
    }
  }

  showProgressBar() {
    if (this.hasProgressBarTarget) {
      this.progressBarTarget.classList.remove('hidden');
      // Optional: Update progress bar width if needed
      const progressBar = this.progressBarTarget.querySelector('div div');
      if (progressBar) {
        progressBar.style.width = '50%'; // Indeterminate progress
      }
    }
  }

  hideProgressBar() {
    if (this.hasProgressBarTarget) {
      this.progressBarTarget.classList.add('hidden');
    }
  }

  getCsrfToken() {
    const metaTag = document.querySelector('meta[name="csrf-token"]');
    return metaTag ? metaTag.content : '';
  }
}