// app/javascript/controllers/search_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.timeout = null
  }

  search(event) {
    // Cancelar el timeout anterior
    clearTimeout(this.timeout)
    
    // Agregar un pequeño debounce para mejorar rendimiento
    this.timeout = setTimeout(() => {
      const query = this.inputTarget.value
      
      // Solo buscar si hay al menos 2 caracteres
      if (query.length >= 2) {
        this.inputTarget.closest('form').requestSubmit()
      }
    }, 300)
  }
}